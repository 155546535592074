<template>
<div>
  <div class="card card-congratulations">
  <div class="card-body text-center">
      <img src="../assets/images/elements/decore-left.png" class="congratulations-img-left" alt="card-img-left">
      <img src="../assets/images/elements/decore-right.png" class="congratulations-img-right" alt="card-img-right">
      <div class="avatar avatar-xl bg-primary shadow">
          <div class="avatar-content">
            👋
          </div>
      </div>
      <div class="text-center">
          <h1 class="mb-1 text-white">Bem Vindo  {{usuario.nomeusu}}</h1>
          <p class="card-text m-auto w-75">
              Em Breve aqui rotinas que auxiliaram em seu Dia a Dia! 🚀
          </p>
      </div>
  </div>
  </div>
  <div class="card-body text-center">
    <img class="img-fluid" src="../assets/images/elements/coming-soon.svg" width="45%" alt="">
  </div>
  </div>
</template>

<script>
import {} from 'bootstrap-vue'

export default {
  components: {
  },
  data() {
    return {
      usuario: '',
      image: '',
    }
  },
  mounted() {
    // Valida Session Ativa
    if (this.$session.exists('usuario')) {
      this.usuario = this.$session.get('usuario')
      this.image = 'data:image/jpg;base64,'.concat(this.image.concat(this.usuario.foto))
    }
  },
}
</script>

<style>

</style>
